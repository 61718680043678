<!--
 * @Descripttion: 搜索页面
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-11-09 14:52:32
-->
<template>
  <wk-header ref="header" :currentIndex="5"></wk-header>
  <div class="min-main">
  <el-container class="search-col">
    <el-row class="w100">
      <el-col :span="8" :offset="8">
        <el-input v-model="searchVal" placeholder="请输入搜索内容">
          <template #append>
            <el-button class="searchBtn" @click="onSearch">搜索</el-button>
          </template>
        </el-input>
      </el-col>
    </el-row>
  </el-container>
  <el-main class="mt20">
    <el-row :gutter="20">
      <el-col :span="18" :offset="3">
        <div class="flex type-title">
          <h2 :class="typeIndex == '1'? 'root-acti': ''" @click="typeIndex = '1'">资讯</h2>
          <h2 :class="typeIndex == '2'? 'root-acti': ''" @click="typeIndex = '2'">工具</h2>
        </div>
       <div class="industry mt20">
          <h3>行业</h3>
          <label
            :class="newsIndustryIndex == item.id ? 'active' : ''"
            v-for="item in newsIndustries"
            :key="item.id"
            v-show="typeIndex == '1'"
            @click="handleNewsIndustry(item)"
          >
            {{ item.name }}
          </label>
          <label
            :class="toolsIndustryIndex == item.id ? 'active' : ''"
            v-for="item in toolsIndustries"
            :key="item.id"
            v-show="typeIndex == '2'"
            @click="handleToolsIndustry(item.id)"
          >
            {{ item.name }}
          </label>
        </div>
        <div class="tool-order" style="margin-top:20px">
          <div>
            <h3>排序</h3>
            <label
              :class="orderIndex == item.id ? 'active' : ''"
              v-for="item in newsOrder"
              :key="item.id"
              @click="handleNewsOrder(item)"
            >
              {{ item.name }}
            </label>
          </div>
        </div>
      </el-col>
      <el-col :span="12" :offset="0"></el-col>
    </el-row>

    <el-container class="tool" v-if="typeIndex == '1'">
      <el-row class="w100" :gutter="20">
        <el-col :span="18" :offset="3">
          <el-row :gutter="50">
            <el-col :span="12" v-for="item in newsList" :key="item">
              <div class="tool-information" @click="handleNewsDetail(item)">
                <img v-if="item.mainPic" :src="item.mainPic">
                <img v-else>
                <div>
                  <p class="tool-information-title el-ellipsis--l2">{{item.title}}</p>
                  <p class="tool-information-industry el-ellipsis root-acti">
                    <!-- {{item.industryStr}} -->
                    <label v-for="l in item.industrys" :key="l.id">{{ l.labelName }}</label>
                  </p>
                  <div class="tool-information-count">
                    <p>{{item.readNums}}人浏览</p>
                    <p v-if="item.duration<60">{{item.duration}}分钟之前发布</p>
                    <p v-else-if="item.duration>=60&&item.duration<1400">{{parseInt(item.duration/60)}}小时之前发布</p>
                    <p v-else-if="item.duration>=1400&&item.duration<2880">24小时之前发布</p>
                    <p v-else-if="item.duration>=2880&&item.duration<4320">48小时之前发布</p>
                    <p v-else>{{item.publishTime}}</p>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-container>
    <el-container v-if="typeIndex == '2'">
      <el-row style="padding-bottom:30px" class="w100">
        <el-col :span="18" :offset="3">
          <el-row :gutter="20">
          <el-col :span="8" v-for="item in toolsList" :key="item">
            <div class="tool-vertical-li" @click="handleToolsDetail(item)">
              <div class="tool-li-title">
                <img class="tool-logo" v-if="item.propagatePic" :src="item.propagatePic">
                <img class="tool-logo" v-else >
                <div class="tool-title-content">
                  <h4 class="el-ellipsis">{{item.toolName}}</h4>
                  <p class="el-ellipsis industry">
                    <label v-for="l in item.industrys" :key="l.id">{{ l.labelName }}</label>
                  </p>
                </div>
                <el-button size="mini" class="tool-btn">查看</el-button>
              </div>
              <div class="tool-desc el-ellipsis--l2">
                <p>{{item.description}}</p>
              </div>
            </div>
          </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-container>
    </el-main>
  </div>
  <wk-footer></wk-footer>
</template>

<script>
import WkHeader  from '@/components/WKHeader'
import WkFooter from '@/components/WKFooter'
import {getAction,postAction} from './../utils/http'
import axios from 'axios'

export default {
  name: 'NewsDetail',
  components: {
    WkHeader,
    WkFooter,
  },
  data() {
    return {
      // 资讯行业类别 id name
      newsIndustries: [],
      newsIndustryIndex: '',
      // 工具行业类别 id name
      toolsIndustries: [],
      toolsIndustryIndex: '',

      // 排序
      newsOrder: [
        {id: '1', name: '最新资讯'},
        {id: '2', name: '最热资讯'},
        {id: '3', name: '浏览最多'},
      ],
      orderIndex: '1',
      currentPage: 1,
      searchVal: '',
      /** 1,资讯; 2, 工具 */
      typeIndex: '1',
      urls:{
        initToolsIndustry:'/wbpt/tools/home/getToolsIndustry',
        initNewsIndustry:'/wbpt/news/home/getNewsIndustry',
        getNews:'/wbpt/news/tbWbptNews/list',
        getTools:'/wbpt/tools/home/getToolsCollection'
      },
      newsList:[],
      toolsList:[],
      newParams:{
        pageNo:1,
        pageSize:10,
        industryId:'',
        newFlag:'',
        hotFlag:'',
        mostReadFlag:'',
        searchVal:'',
        title:'',
      },
      newsLoading: false,
      toolsParams:{
        page:1,
        pageSize:12,
        industryId:'',
        newFlag:'',
        hotFlag:'',
        mostReadFlag:'',
        toolName:'',
      },
      toolsTotal:0,
      toolsPageSize:10,
      toolLoading: false,
      toolFinished: false,
      newsFinished: false
    }
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.initScroll, true)
  },
  created(){

    //初始化工具行业分类
    this.getToolsIndustry();
    //初始化资讯行业分类
    this.getNewsIndustry();
    this.searchVal =  this.$route.query.val || '';
    this.onSearch();
    this.$nextTick(() => {
      window.addEventListener('scroll', this.initScroll, true)
    })

  },
  watch:{
    '$route.path': function (newVal) {
      this.searchVal = newVal.substr(8);
      this.onSearch();
    }
  },
  methods: {
    handleNewsIndustry({id}) {
      this.newsIndustryIndex = id;
      this.newParams.industryId = id;
      this.newParams.pageNo = 1;
      // this.newParams.title = this.searchVal;
      this.newsList = [];
      this.getNewsList();
    },

    handleToolsIndustry(id) {
      this.toolsIndustryIndex = id;
      this.toolsParams.page = 1;
      this.toolsParams.industryId = id;
      // this.toolsParams.toolName = this.searchVal;
      this.toolsList= [];
      this.getToolsCollection();
    },

    handleNewsOrder({id}) {
      this.orderIndex = id;
      this.newParams.newFlag ='';
      this.newParams.hotFlag ='';
      this.newParams.mostReadFlag =''

      this.toolsParams.newFlag ='';
      this.toolsParams.hotFlag ='';
      this.toolsParams.mostReadFlag =''

      if('1'==id){
        this.newParams.newFlag ='1'
        this.toolsParams.newFlag ='1'
      }else if('2'==id){
        this.newParams.hotFlag ='1'
        this.toolsParams.hotFlag ='1'
      }else if('3'==id){
        this.newParams.mostReadFlag ='1'
        this.toolsParams.mostReadFlag ='1'
      }else{
        //不做处理
      }
      this.onSearch();
    },
    //资讯行业初始化
		getNewsIndustry(){
			this.newsIndustries = []
			getAction(this.urls.initNewsIndustry).then(res=>{
				if(res.rows){
					for(let ii=0;ii<res.rows.length;ii++){
						this.newsIndustries.push({
							id:res.rows[ii].id,
							name:res.rows[ii].labelName
						})
					}
				}
			}).catch(err=>{
          // ElMessage.error('获取资讯行业初始化出错!')
          console.log(err);
      });
		},
    //工具行业初始化
		getToolsIndustry(){
			this.toolsIndustries = [];
			getAction(this.urls.initToolsIndustry).then(res=>{
				if(res.rows){
					for(let ii=0;ii<res.rows.length;ii++){
						this.toolsIndustries.push({
							id:res.rows[ii].id,
							name:res.rows[ii].labelName
						})
					}
				}
			}).catch(err=>{
          // ElMessage.error('获取工具行业初始化出错!')
          console.log(err);
      });
		},
    //查询资讯
    getNewsList(){
      this.newsLoading = true;
      getAction(this.urls.getNews,this.newParams).then(res=>{
        if(res.success){
          this.newsList = [...this.newsList, ...res.result.records]
          if(this.newsList.length >= res.result.total)
            this.newsFinished = true
          console.log("-------------:" + this.newsFinished)
        }else{
          console.log('获取资讯失败！');
          // ElMessage.error('获取资讯失败！')
        }
      }).catch(err=>{
          // ElMessage.error('获取资讯出错!')
          console.log(err);
      }).finally(()=>{
        this.newsLoading = false;
      });
    },
		//查询工具集合
		getToolsCollection(){
      this.toolLoading = true;
			postAction(this.urls.getTools,this.toolsParams).then(res=>{
				if(res.rows){
          this.toolsList = this.toolsList.concat(res.rows)
          if(this.toolsList.length >= res.total){
            this.toolFinished = true
          }
          if(this.newsList.length==0&&this.toolsList.length>0){
            this.typeIndex = '2';
          }
				}
			}).finally(()=>{
        this.toolLoading = false;
      });
		},
    //查询
    onSearch(){
      this.newParams.pageNo = 1;
      this.newParams.title = this.searchVal;
      this.newsList= [];
      this.getNewsList();

      this.toolsParams.page = 1;
      this.toolsParams.toolName = this.searchVal;
      this.toolsList= [];
      this.getToolsCollection();

    },
    /**
     * @Description: 滚动条的触底操作（距离底部50px时，开始加载下一页）
     * @Author: Niklaus
     * @Param:
     * @Return:
     * @Date: 2021-11-10 14:37:49
     */
    initScroll() {
      let scropHeight = document.documentElement.scropHeight || document.body.scrollHeight
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      let clientHeight = document.documentElement.clientHeight || document.body.clientHeight

      if(this.typeIndex == 1 && !this.newsFinished) {
        if(scropHeight - scrollTop - clientHeight <= 50 &&this.newsLoading==false) {
          this.newParams.pageNo++;
          this.getNewsList();
        }
      }else if(this.typeIndex == 2 && !this.toolFinished) {
        if(scropHeight - scrollTop - clientHeight <= 50 &&this.tool==false) {
          this.toolsParams.page++;
          this.getToolsCollection();
        }
      }
    },
    //资讯详情
    handleNewsDetail(item) {
      this.addNewsReadnum(item.id)
      if(item.newsUrlPc){
        window.open(item.newsUrlPc,'_blank');
      }else{
        this.$router.push({
          path: '/newsDetail',
          query: {
            newsId: item.id
          }
        })
      }
    }, 
    //阅读数加1
		addNewsReadnum:function(id){
      let ip = "";
      if(sessionStorage.getItem('ip')){
        ip = sessionStorage.getItem('ip')
      }        
      let userId = '';
      if(this.$ls.get("wikeHome_current_userInfo")){
        userId = this.$ls.get("wikeHome_current_userInfo").id;
      }
      let obj={
        newsId:id,
        userId:userId,
        ip:ip
      }
      getAction("/wbpt/news/home/updateNewsReadnumWithId",obj).then(res=>{
        console.log(res)
      })    
		},          
    //工具详情
    handleToolsDetail(item){
      // this.$ls.set("currentTool",item);
      // this.$router.push('/toolDetail') 
      this.$router.push({
        path: '/toolDetail',
        query: {
          toolId: item.id
        }
      })
    },

  }
}
</script>

<style scoped>
  .search-col {
    background: #F2F8FF;
    padding: 30px 0;
    display: block;
  }
  .type-title h2{
    cursor: pointer;
    margin-right: 40px;
  }
  .industry,
  .tool-information-industry {
    min-height: 19px;
  }
  .industry > label:not(:last-child),
  .tool-information-industry > label:not(:last-child) {
    display: inline-block;
    margin-right: 15px;
  }
  p.industry {
    display: block;

  }
  p.industry > label {
    margin-left: 0;
  }
</style>